import React from 'react';

const ArrowUpIcon = () => {
    return (
        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.30727 16.1527L8.30158 16.1784L8.30158 5.17593L11.816 8.6347C11.9876 8.80407 12.22 8.89697 12.464 8.89697C12.7079 8.89697 12.9387 8.80407 13.1107 8.6347L13.6566 8.09651C13.8282 7.92728 13.9231 7.70176 13.9231 7.46128C13.9231 7.22066 13.8289 6.99501 13.6573 6.82578L7.6467 0.896788C7.47444 0.726884 7.24499 0.633979 7.0009 0.634647C6.75546 0.633979 6.52587 0.726884 6.35388 0.896788L0.342716 6.82578C0.17127 6.99501 0.0769415 7.22053 0.0769415 7.46128C0.0769415 7.70176 0.171406 7.92728 0.342716 8.09651L0.888631 8.6347C1.05994 8.80407 1.28872 8.89697 1.53267 8.89697C1.77649 8.89697 1.9932 8.80407 2.16465 8.6347L5.69873 5.1369L5.69873 16.165C5.69873 16.6605 6.13175 17.077 6.63388 17.077H7.406C7.90814 17.077 8.30727 16.6482 8.30727 16.1527Z" fill="white"/>
        </svg>
    );
};

export default ArrowUpIcon;