import React from 'react';

const ArrowDownIcon = () => {
    return (
        <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.33331 9.88892L0.447587 2.48419C-0.0732664 1.99277 -0.151394 1.11157 0.275706 0.513736C0.701764 -0.0840945 1.4695 -0.173769 1.9914 0.316452L8.33331 6.27204L14.6752 0.316452C15.1961 -0.173769 15.9649 -0.0840945 16.3909 0.513736C16.818 1.11157 16.7399 1.99277 16.219 2.48419L8.33331 9.88892Z" fill="currentColor"/>
        </svg>
    );
};

export default ArrowDownIcon;