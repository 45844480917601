import React, {createRef, useEffect, useState} from 'react';
import {Col, Menu, Row, Select} from 'antd';
import {Link} from 'gatsby';
import {useIntl, changeLocale} from 'gatsby-plugin-intl';
import img from '../../assets/mia-brand-logo.png';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import MobileMenu from '../mobile-menu/MobileMenu';
import {globalHistory} from '@reach/router';
import styles from './Header.module.less';
import browserLang from 'browser-lang';
import {HeaderMenuItems} from './HeaderMenuItems';
import {AppBar, ClickAwayListener} from '@material-ui/core';
import HideOnScroll from './HideOnScroll';

const MIAHeader = () => {
    const {formatMessage, locale} = useIntl();
    const {Option} = Select;
    const selectRef = createRef();
    const [selectActive, setSelectActive] = useState(false);
    const browserLanguage = browserLang();
    let defaultLanguage = 'en';

    if (typeof localStorage !== 'undefined'
        && !localStorage.getItem('selectedLanguage')
        && ['de', 'en'].indexOf(browserLanguage) !== -1
    ) {
        defaultLanguage = browserLanguage;
        changeLocale(browserLanguage);
        localStorage.setItem('selectedLanguage', browserLanguage);
    }

    const handleChange = (value) => {
        changeLocale(value);
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem('selectedLanguage', value);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleHide, true);
        window.addEventListener('resize', handleHide, true);

        return () => {
            document.removeEventListener('scroll', handleHide);
            window.removeEventListener('resize', handleHide);
        };
    });

    const handleHide = () => {
        if (selectRef.current === null) {
            return;
        }
        if (setSelectActive) {
            setSelectActive(false);
        }
    };

    const localeLength = 3;
    let selectedMenuItem = globalHistory.location.pathname.substring(
        localeLength,
        globalHistory.location.pathname.substring(localeLength + 1).indexOf('/') !== -1
            ? globalHistory.location.pathname.substring(4).indexOf('/') + localeLength + 1
            : undefined
    );

    return (
        <HideOnScroll>
            <AppBar color="inherit" className="ant-layout-header">
                <div className="container">
                    <Row className={styles.topBar}>
                        <Col className={styles.brand}>
                            <Link to={`/${locale}/`}>
                                <img src={img}
                                     className={styles.logo}
                                     title={formatMessage({id: 'logo.title'})}
                                     alt={formatMessage({id: 'logo.alt-text'})}
                                />
                            </Link>
                        </Col>
                        <Col className={styles.nav}>
                            <Menu
                                mode="horizontal"
                                selectedKeys={[selectedMenuItem]}
                            >
                                {HeaderMenuItems.map(menuItem => (
                                    <Menu.Item key={menuItem.key}>
                                        <Link to={`/${locale}${menuItem.link}`}>{formatMessage({id: `${menuItem.translationId}`})}</Link>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </Col>
                        <ClickAwayListener onClickAway={() => setSelectActive(false)}>
                            <Col className={styles.select} onClick={() => setSelectActive(!selectActive)}>
                                <Select
                                    ref={selectRef}
                                    showArrow
                                    defaultValue={locale ? locale : defaultLanguage}
                                    onChange={handleChange}
                                    open={selectActive}
                                    suffixIcon={<ArrowDownIcon />}
                                    className={'custom-select'}
                                    dropdownClassName={'custom-select-dropdown'}
                                >
                                    <Option value={'de'}>Deutsch</Option>
                                    <Option value={'en'}>English</Option>
                                </Select>
                                <span className={'custom-select-value'} onClick={() => selectRef.current.focus()}>{locale}</span>
                            </Col>
                        </ClickAwayListener>
                        <Col>
                            <MobileMenu handleChangeLanguage={handleChange} selectedKeys={[selectedMenuItem]}/>
                        </Col>
                    </Row>
                </div>
            </AppBar>
        </HideOnScroll>
    );
};

export default MIAHeader;
