import React from 'react';

import AppSoftwareDevelopmentIcon from '../components/icons/competencies-icons/AppSoftwareDevelopmentIcon';
import AppSoftwareDevelopmentActiveIcon from '../components/icons/competencies-icons/AppSoftwareDevelopmentActiveIcon';
import appSoftwareDevelopmentImage from '../assets/competencies-images/app-softwareentwicklung.png';
import GraphicDesignIcon from '../components/icons/competencies-icons/GraphicDesignIcon';
import GraphicDesignActiveIcon from '../components/icons/competencies-icons/GraphicDesignActiveIcon';
import graphicDesignImage from '../assets/competencies-images/grafik-design.png';
import IllustrationIcon from '../components/icons/competencies-icons/IllustrationIcon';
import IllustrationActiveIcon from '../components/icons/competencies-icons/IllustrationActiveIcon';
import illustrationImage from '../assets/competencies-images/illustration.png';
import ProductDevelopmentIcon from '../components/icons/competencies-icons/ProductDevelopmentIcon';
import ProductDevelopmentActiveIcon from '../components/icons/competencies-icons/ProductDevelopmentActiveIcon';
import productDevelopmentImage from '../assets/competencies-images/produktentwicklung.png';
import ProjectManagementIcon from '../components/icons/competencies-icons/ProjectManagementIcon';
import ProjectManagementActiveIcon from '../components/icons/competencies-icons/ProjectManagementActiveIcon';
import projectManagementImage from '../assets/competencies-images/projektmanagement.png';
import WebDesignIcon from '../components/icons/competencies-icons/WebDesignIcon';
import WebDesignActiveIcon from '../components/icons/competencies-icons/WebDesignActiveIcon';
import webDesignImage from '../assets/competencies-images/web-design.png';

const competenciesConfig = [
    {
        id: 'app-software-development',
        title: 'app-software-development.title',
        excerpt: 'app-software-development.excerpt',
        label: 'app-software-development.label',
        linkLabel: 'app-software-development.link.label',
        heading: 'app-software-development.heading',
        text: 'app-software-development.text',
        icon: <AppSoftwareDevelopmentIcon/>,
        activeIcon: <AppSoftwareDevelopmentActiveIcon/>,
        image: appSoftwareDevelopmentImage,
        color: '#00C7EC',
        order: 10,
    },
    {
        id: 'graphic-design',
        title: 'graphic-design.title',
        excerpt: 'graphic-design.excerpt',
        label: 'graphic-design.label',
        linkLabel: 'graphic-design.link.label',
        heading: 'graphic-design.heading',
        text: 'graphic-design.text',
        icon: <GraphicDesignIcon/>,
        activeIcon: <GraphicDesignActiveIcon/>,
        image: graphicDesignImage,
        color: '#46CDE2',
        order: 20,
    },
    {
        id: 'illustration',
        title: 'illustration.title',
        excerpt: 'illustration.excerpt',
        label: 'illustration.label',
        linkLabel: 'illustration.link.label',
        heading: 'illustration.heading',
        text: 'illustration.text',
        icon: <IllustrationIcon/>,
        activeIcon: <IllustrationActiveIcon/>,
        image: illustrationImage,
        color: '#E7434F',
        order: 30,
    },
    {
        id: 'project-management',
        title: 'project-management.title',
        excerpt: 'project-management.excerpt',
        label: 'project-management.label',
        linkLabel: 'project-management.link.label',
        heading: 'project-management.heading',
        text: 'project-management.text',
        icon: <ProjectManagementIcon/>,
        activeIcon: <ProjectManagementActiveIcon/>,
        image: projectManagementImage,
        color: '#6E525D',
        order: 40,
    },
    {
        id: 'product-development',
        title: 'product-development.title',
        excerpt: 'product-development.excerpt',
        label: 'product-development.label',
        linkLabel: 'product-development.link.label',
        heading: 'product-development.heading',
        text: 'product-development.text',
        icon: <ProductDevelopmentIcon/>,
        activeIcon: <ProductDevelopmentActiveIcon/>,
        image: productDevelopmentImage,
        color: '#F1757F',
        order: 50,
    },
    {
        id: 'web-design',
        title: 'web-design.title',
        excerpt: 'web-design.excerpt',
        label: 'web-design.label',
        linkLabel: 'web-design.link.label',
        heading: 'web-design.heading',
        text: 'web-design.text',
        icon: <WebDesignIcon/>,
        activeIcon: <WebDesignActiveIcon/>,
        image: webDesignImage,
        color: '#5B1E35',
        order: 60,
    },
];

export default competenciesConfig;
