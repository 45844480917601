export const HeaderMenuItems = [
    {
        key: '/',
        link: '/',
        translationId: 'nav.label.home',
    },
    {
        key: '/about',
        link: '/about',
        translationId: 'nav.label.about',
    },
    {
        key: '/magazine',
        link: '/magazine',
        translationId: 'nav.label.magazine',
    },
    // {
    //     key: '/product',
    //     link: '/product',
    //     translationId: 'nav.label.products',
    // },
    {
        key: '/jobs',
        link: '/jobs',
        translationId: 'nav.label.jobs',
    },
    {
        key: '/services',
        link: '/services',
        translationId: 'nav.label.services',
    },
    {
        key: '/contact',
        link: '/contact',
        translationId: 'nav.label.contact',
    },
];
