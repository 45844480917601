import React from 'react';

const AppSoftwareDevelopmentIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M41 81C63.0914 81 81 63.0914 81 41C81 18.9086 63.0914 1 41 1C18.9086 1 1 18.9086 1 41C1 63.0914 18.9086 81 41 81Z" stroke="#00C2E9" strokeWidth="2"/>
            <path d="M53.4999 63.5C53.4996 63.7209 53.4117 63.9328 53.2555 64.089C53.0993 64.2452 52.8875 64.3331 52.6665 64.3333H29.3332C29.1123 64.3331 28.9004 64.2452 28.7442 64.089C28.588 63.9328 28.5001 63.7209 28.4999 63.5V54.3333H26.8332V63.5C26.8339 64.1628 27.0976 64.7983 27.5663 65.2669C28.0349 65.7356 28.6704 65.9992 29.3332 66H52.6665C53.3293 65.9992 53.9648 65.7356 54.4335 65.2669C54.9021 64.7983 55.1658 64.1628 55.1665 63.5V46H53.4999V63.5Z" fill="#00C2E9"/>
            <path d="M28.4999 18.5C28.5001 18.2791 28.588 18.0672 28.7442 17.911C28.9004 17.7548 29.1123 17.6669 29.3332 17.6667H52.6665C52.8875 17.6669 53.0993 17.7548 53.2555 17.911C53.4117 18.0672 53.4996 18.2791 53.4999 18.5V31H55.1665V18.5C55.1658 17.8372 54.9021 17.2017 54.4335 16.7331C53.9648 16.2644 53.3293 16.0008 52.6665 16H29.3332C28.6704 16.0008 28.0349 16.2644 27.5663 16.7331C27.0976 17.2017 26.8339 17.8372 26.8332 18.5V26H28.4999V18.5Z" fill="#00C2E9"/>
            <path d="M52.6665 56.8333V46H50.9999V56H30.9999V54.3333H29.3332V56.8333C29.3332 56.9428 29.3547 57.0511 29.3966 57.1522C29.4385 57.2533 29.4999 57.3452 29.5773 57.4226C29.6546 57.5 29.7465 57.5614 29.8476 57.6032C29.9487 57.6451 30.0571 57.6667 30.1665 57.6667H51.8332C51.9426 57.6667 52.051 57.6451 52.1521 57.6032C52.2532 57.5614 52.3451 57.5 52.4224 57.4226C52.4998 57.3452 52.5612 57.2533 52.6031 57.1522C52.645 57.0511 52.6665 56.9428 52.6665 56.8333Z" fill="#00C2E9"/>
            <path d="M52.6665 31.0002V23.5002C52.6665 23.3908 52.645 23.2824 52.6031 23.1813C52.5612 23.0802 52.4998 22.9883 52.4224 22.9109C52.3451 22.8336 52.2532 22.7722 52.1521 22.7303C52.051 22.6884 51.9426 22.6669 51.8332 22.6669H30.1665C30.0571 22.6669 29.9487 22.6884 29.8476 22.7303C29.7465 22.7722 29.6546 22.8336 29.5773 22.9109C29.4999 22.9883 29.4385 23.0802 29.3966 23.1813C29.3547 23.2824 29.3332 23.3908 29.3332 23.5002V26.0002H30.9999V24.3335H50.9999V31.0002H52.6665Z" fill="#00C2E9"/>
            <path d="M39.3332 19.3331H44.3332V20.9998H39.3332V19.3331Z" fill="#00C2E9"/>
            <path d="M37.6665 20.9998C38.1268 20.9998 38.4999 20.6267 38.4999 20.1665C38.4999 19.7062 38.1268 19.3331 37.6665 19.3331C37.2063 19.3331 36.8332 19.7062 36.8332 20.1665C36.8332 20.6267 37.2063 20.9998 37.6665 20.9998Z" fill="#00C2E9"/>
            <path d="M38.5 61C38.5 61.4945 38.6466 61.9778 38.9213 62.3889C39.196 62.8 39.5865 63.1205 40.0433 63.3097C40.5001 63.4989 41.0028 63.5484 41.4877 63.452C41.9727 63.3555 42.4181 63.1174 42.7678 62.7678C43.1174 62.4181 43.3555 61.9727 43.452 61.4877C43.5484 61.0028 43.4989 60.5001 43.3097 60.0433C43.1205 59.5865 42.8 59.196 42.3889 58.9213C41.9778 58.6466 41.4945 58.5 41 58.5C40.3372 58.5007 39.7017 58.7644 39.2331 59.2331C38.7644 59.7017 38.5007 60.3372 38.5 61ZM41.8333 61C41.8333 61.1648 41.7845 61.3259 41.6929 61.463C41.6013 61.6 41.4712 61.7068 41.3189 61.7699C41.1666 61.833 40.9991 61.8495 40.8374 61.8173C40.6758 61.7852 40.5273 61.7058 40.4107 61.5893C40.2942 61.4727 40.2148 61.3242 40.1827 61.1626C40.1505 61.0009 40.167 60.8334 40.2301 60.6811C40.2932 60.5288 40.4 60.3987 40.537 60.3071C40.6741 60.2155 40.8352 60.1667 41 60.1667C41.2209 60.1669 41.4328 60.2548 41.589 60.411C41.7452 60.5672 41.8331 60.7791 41.8333 61Z" fill="#00C2E9"/>
            <path d="M36 50.1667V45.5119L39.0892 42.4226C39.2058 42.306 39.2852 42.1576 39.3173 41.9959C39.3495 41.8343 39.333 41.6667 39.2699 41.5144C39.2068 41.3621 39.1 41.232 38.963 41.1404C38.8259 41.0489 38.6648 41 38.5 41H18.5C17.8372 41.0008 17.2017 41.2644 16.7331 41.7331C16.2644 42.2017 16.0008 42.8372 16 43.5V50.1667C16.0008 50.8295 16.2644 51.4649 16.7331 51.9336C17.2017 52.4023 17.8372 52.6659 18.5 52.6667H33.5C34.1628 52.6659 34.7983 52.4023 35.2669 51.9336C35.7356 51.4649 35.9992 50.8295 36 50.1667ZM17.6667 50.1667V43.5C17.6669 43.2791 17.7548 43.0672 17.911 42.911C18.0672 42.7548 18.2791 42.6669 18.5 42.6667H36.4881L34.5774 44.5774C34.5 44.6548 34.4386 44.7467 34.3968 44.8478C34.3549 44.9489 34.3333 45.0572 34.3333 45.1667V50.1667C34.3331 50.3876 34.2452 50.5994 34.089 50.7556C33.9328 50.9119 33.7209 50.9997 33.5 51H18.5C18.2791 50.9997 18.0672 50.9119 17.911 50.7556C17.7548 50.5994 17.6669 50.3876 17.6667 50.1667Z" fill="#00C2E9"/>
            <path d="M18.5 39.3335H38.5C38.6648 39.3335 38.8259 39.2847 38.963 39.1931C39.1 39.1015 39.2068 38.9714 39.2699 38.8191C39.333 38.6668 39.3495 38.4993 39.3173 38.3376C39.2852 38.176 39.2058 38.0275 39.0892 37.911L36 34.8217V30.1669C35.9992 29.5041 35.7356 28.8686 35.2669 28.3999C34.7983 27.9313 34.1628 27.6676 33.5 27.6669H18.5C17.8372 27.6676 17.2017 27.9313 16.7331 28.3999C16.2644 28.8686 16.0008 29.5041 16 30.1669V36.8335C16.0008 37.4963 16.2644 38.1318 16.7331 38.6005C17.2017 39.0691 17.8372 39.3328 18.5 39.3335ZM17.6667 30.1669C17.6669 29.9459 17.7548 29.7341 17.911 29.5779C18.0672 29.4217 18.2791 29.3338 18.5 29.3335H33.5C33.7209 29.3338 33.9328 29.4217 34.089 29.5779C34.2452 29.7341 34.3331 29.9459 34.3333 30.1669V35.1669C34.3333 35.2763 34.3549 35.3847 34.3968 35.4858C34.4386 35.5869 34.5 35.6787 34.5774 35.7561L36.4881 37.6669H18.5C18.2791 37.6666 18.0672 37.5787 17.911 37.4225C17.7548 37.2663 17.6669 37.0545 17.6667 36.8335V30.1669Z" fill="#00C2E9"/>
            <path d="M63.5002 32.6669H48.5002C47.8373 32.6676 47.2019 32.9313 46.7332 33.3999C46.2645 33.8686 46.0009 34.5041 46.0002 35.1669V39.8217L42.9109 42.911C42.7944 43.0275 42.715 43.176 42.6828 43.3376C42.6507 43.4993 42.6672 43.6668 42.7302 43.8191C42.7933 43.9714 42.9001 44.1015 43.0372 44.1931C43.1742 44.2847 43.3353 44.3335 43.5002 44.3335H63.5002C64.163 44.3328 64.7984 44.0691 65.2671 43.6005C65.7358 43.1318 65.9994 42.4963 66.0002 41.8335V35.1669C65.9994 34.5041 65.7358 33.8686 65.2671 33.3999C64.7984 32.9313 64.163 32.6676 63.5002 32.6669ZM64.3335 41.8335C64.3332 42.0545 64.2454 42.2663 64.0891 42.4225C63.9329 42.5787 63.7211 42.6666 63.5002 42.6669H45.512L47.4227 40.7561C47.5001 40.6787 47.5615 40.5869 47.6034 40.4858C47.6453 40.3847 47.6668 40.2763 47.6668 40.1669V35.1669C47.6671 34.9459 47.7549 34.7341 47.9112 34.5779C48.0674 34.4217 48.2792 34.3338 48.5002 34.3335H63.5002C63.7211 34.3338 63.9329 34.4217 64.0891 34.5779C64.2454 34.7341 64.3332 34.9459 64.3335 35.1669V41.8335Z" fill="#00C2E9"/>
            <path d="M21 31H24.3333V32.6667H21V31Z" fill="#00C2E9"/>
            <path d="M26 31H32.6667V32.6667H26V31Z" fill="#00C2E9"/>
            <path d="M19.3332 34.3331H22.6665V35.9998H19.3332V34.3331Z" fill="#00C2E9"/>
            <path d="M24.3332 34.3331H29.3332V35.9998H24.3332V34.3331Z" fill="#00C2E9"/>
            <path d="M51 36H56V37.6667H51V36Z" fill="#00C2E9"/>
            <path d="M57.6668 36H62.6668V37.6667H57.6668V36Z" fill="#00C2E9"/>
            <path d="M49.3332 39.3332H59.3332V40.9999H49.3332V39.3332Z" fill="#00C2E9"/>
            <path d="M21 44.3332H24.3333V45.9999H21V44.3332Z" fill="#00C2E9"/>
            <path d="M26 44.3332H29.3333V45.9999H26V44.3332Z" fill="#00C2E9"/>
            <path d="M31 44.3332H32.6667V45.9999H31V44.3332Z" fill="#00C2E9"/>
            <path d="M19.3332 47.6668H25.9999V49.3335H19.3332V47.6668Z" fill="#00C2E9"/>
            <path d="M27.6668 47.6668H32.6668V49.3335H27.6668V47.6668Z" fill="#00C2E9"/>
        </svg>
    );
};

export default AppSoftwareDevelopmentIcon;
