import React from 'react';

const CheckIcon = () => {
    return (
        <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.8016 1.17271C15.3402 0.52504 16.2972 0.440561 16.9391 0.984021C17.581 1.52748 17.6647 2.49308 17.1261 3.14075L8.02391 14.086C7.4853 14.7337 6.52832 14.8182 5.88644 14.2747C5.24455 13.7313 5.16082 12.7657 5.69943 12.118L14.8016 1.17271Z" fill="#00C2E9"/>
            <path d="M3.92519 6.28978C3.38658 5.64211 2.4296 5.55763 1.78771 6.10109C1.14582 6.64455 1.0621 7.61015 1.60071 8.25782L5.65847 14.1477C6.19708 14.7953 7.15406 14.8798 7.79595 14.3364C8.43783 13.7929 8.52156 12.8273 7.98295 12.1796L3.92519 6.28978Z" fill="#00C2E9"/>
        </svg>
    )
}

export default CheckIcon;
