import React from 'react';

const ProjectManagementActiveIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="currentColor"/>
            <path d="M45.25 47.3435C45.25 47.4298 45.1801 47.4998 45.0938 47.4998H27.75V48.7498H45.0938C45.87 48.7488 46.4991 48.1198 46.5 47.3435V24.9998H45.25V47.3435Z" fill="white"/>
            <path d="M45.0942 11.25H22.2817C21.5055 11.2509 20.8764 11.88 20.8755 12.6562V23.75H22.1255V12.6562C22.1255 12.5699 22.1954 12.5 22.2817 12.5H45.0942C45.1806 12.5 45.2505 12.5699 45.2505 12.6562V23.75H46.5005V12.6562C46.4995 11.88 45.8705 11.2509 45.0942 11.25Z" fill="white"/>
            <path d="M42.9331 26.4331L39.0001 30.3662L37.567 28.9331L36.6831 29.817L38.5581 31.692C38.8022 31.9361 39.1979 31.9361 39.442 31.692L43.817 27.317L42.9331 26.4331Z" fill="white"/>
            <path d="M34.6245 33.75C34.6245 34.0952 34.9044 34.375 35.2495 34.375H41.4995V33.125H35.8745V27.5H40.2495V26.25H35.2495C34.9044 26.25 34.6245 26.5298 34.6245 26.875V33.75Z" fill="white"/>
            <path d="M37.567 39.5581L36.6831 40.442L38.5581 42.317C38.8022 42.5611 39.1979 42.5611 39.442 42.317L43.817 37.942L42.9331 37.0581L39.0001 40.9912L37.567 39.5581Z" fill="white"/>
            <path d="M34.6245 44.3752C34.6245 44.7204 34.9044 45.0002 35.2495 45.0002H41.4995V43.7502H35.8745V38.1252H40.2495V36.8752H35.2495C34.9044 36.8752 34.6245 37.1551 34.6245 37.5002V44.3752Z" fill="white"/>
            <path d="M42.9331 15.1831L39.0001 19.1162L37.567 17.6831L36.6831 18.567L38.5581 20.442C38.8022 20.6861 39.1979 20.6861 39.442 20.442L43.817 16.067L42.9331 15.1831Z" fill="white"/>
            <path d="M34.6245 15.625V22.5C34.6245 22.8452 34.9044 23.125 35.2495 23.125H41.4995V21.875H35.8745V16.25H40.2495V15H35.2495C34.9044 15 34.6245 15.2798 34.6245 15.625Z" fill="white"/>
            <path d="M26.317 17.6831L25.4331 18.567L27.3081 20.442C27.5522 20.6861 27.9479 20.6861 28.192 20.442L32.567 16.067L31.6831 15.1831L27.7501 19.1162L26.317 17.6831Z" fill="white"/>
            <path d="M23.3745 15.625V22.5C23.3745 22.8452 23.6544 23.125 23.9995 23.125H30.2495V21.875H24.6245V16.25H28.9995V15H23.9995C23.6544 15 23.3745 15.2798 23.3745 15.625Z" fill="white"/>
            <path d="M32.75 36.8748C32.75 30.3269 27.4229 24.9998 20.875 24.9998C14.3271 24.9998 9 30.3269 9 36.8748H10.25C10.25 31.0162 15.0163 26.2498 20.875 26.2498C26.7337 26.2498 31.5 31.0162 31.5 36.8748C31.5 42.7333 26.7337 47.4998 20.875 47.4998C15.4666 47.4927 10.9236 43.4299 10.3152 38.0558L9.07297 38.1937C9.75187 44.2008 14.8297 48.7426 20.875 48.7498C27.4229 48.7498 32.75 43.4226 32.75 36.8748Z" fill="white"/>
            <path d="M20.2505 37.8246L17.3599 40.1371L18.1411 41.1132L21.2661 38.6132C21.4143 38.4945 21.5006 38.3149 21.5005 38.125V31.875H20.2505V37.8246Z" fill="white"/>
            <path d="M21.5 28.7739C22.075 28.8177 22.6436 28.9229 23.1962 29.0877L23.5538 27.8899C18.5838 26.4022 13.3486 29.2252 11.8609 34.1952C10.3731 39.1653 13.1961 44.4005 18.1662 45.8882C23.1363 47.3759 28.3714 44.553 29.8592 39.5829C31.1884 35.1423 29.0799 30.396 24.8938 28.4055L24.3563 29.5341C25.0061 29.8437 25.6117 30.2389 26.157 30.7089L25.2945 31.5715L26.1783 32.4555L27.0391 31.595C28.1593 32.9037 28.8371 34.533 28.9756 36.2501H27.75V37.5001H28.9766C28.845 39.2194 28.1675 40.8516 27.043 42.1588L26.1787 41.2945L25.2949 42.1784L26.1591 43.0427C24.8519 44.1674 23.2195 44.845 21.5 44.9766V43.7501H20.25V44.9766C18.5308 44.8451 16.8985 44.1676 15.5913 43.0431L16.4556 42.1788L15.5717 41.2948L14.7074 42.1588C13.5829 40.8516 12.9054 39.2194 12.7738 37.5001H14V36.2501H12.7738C12.9054 34.5308 13.5829 32.8985 14.7074 31.5913L15.5717 32.4557L16.4556 31.5717L15.5913 30.7074C16.8985 29.583 18.5308 28.9055 20.25 28.7739V30.0001H21.5V28.7739Z" fill="white"/>
        </svg>
    );
};

export default ProjectManagementActiveIcon;
