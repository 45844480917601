import React, {useEffect} from 'react';
import styles from './CookiesWarning.module.less';
import {Link} from 'gatsby';
import {AnchorLink} from 'gatsby-plugin-anchor-links';
import {Button, Col, Row} from 'antd';
import {useIntl} from 'gatsby-plugin-intl';
import {FormattedMessage} from 'gatsby-plugin-intl/index';

const CookiesWarning = () => {
    const {formatMessage, locale} = useIntl();

    const getCookie = (cookieName) => {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookiesArray = decodedCookie.split(';');

        for (let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }

        return '';
    };

    const setCookie = (name, value, expirationDays) => {
        let d = new Date();
        d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = name + '=' + value + ';' + expires + ';path=/;SameSite=Lax';
    };

    const checkCookie = () => {
        const accepted = getCookie('IAM-MIA-accepted');
        const banner = document.getElementById('cookie_warning');

        if (!accepted) {
            banner.classList.remove('d-none');
        } else {
            banner.classList.add('d-none');
        }
    };

    const setCookieAndCheck = (name, value, expirationDays) => {
        setCookie(name, value, expirationDays);
        checkCookie();
    };

    useEffect(() => checkCookie());

    return (
        <section id="cookie_warning" className={`${styles.fixedBanner} d-none`}>
            <div className={`${styles.container} container`}>
                <Row gutter={[20, 20]} type={'flex'} justify={'space-between'} className={styles.row}>
                    <Col lg={{span: 8}}>
                        <FormattedMessage
                            id={'cookie.description'}
                            values={{
                                a1: (chunks) => <AnchorLink className={styles.link} to={`/${locale}/legal/#cookies`}>{chunks}</AnchorLink>,
                                a2: (chunks) => <Link className={styles.link} to={`/${locale}/legal`}>{chunks}</Link>
                            }}
                        >
                            {(chunks) => <div>{chunks}</div>}
                        </FormattedMessage>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            className={styles.button}
                            onClick={() => setCookieAndCheck('IAM-MIA-accepted', true, 365)}
                        >{formatMessage({id: 'cookie.button.label'})}</Button>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default CookiesWarning;