import React from 'react';

const WebDesignIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M41 81C63.0914 81 81 63.0914 81 41C81 18.9086 63.0914 1 41 1C18.9086 1 1 18.9086 1 41C1 63.0914 18.9086 81 41 81Z" stroke="#00C2E9" strokeWidth="2"/>
            <path d="M63.5 17H18.5C17.8372 17.0008 17.2018 17.2644 16.7331 17.7331C16.2644 18.2018 16.0008 18.8372 16 19.5V62.8333C16.0008 63.4961 16.2644 64.1316 16.7331 64.6003C17.2018 65.0689 17.8372 65.3326 18.5 65.3333H63.5C64.1628 65.3326 64.7982 65.0689 65.2669 64.6003C65.7356 64.1316 65.9992 63.4961 66 62.8333V19.5C65.9992 18.8372 65.7356 18.2018 65.2669 17.7331C64.7982 17.2644 64.1628 17.0008 63.5 17ZM18.5 18.6667H63.5C63.7209 18.6669 63.9328 18.7548 64.089 18.911C64.2452 19.0672 64.3331 19.2791 64.3333 19.5V23.6667H17.6667V19.5C17.6669 19.2791 17.7548 19.0672 17.911 18.911C18.0672 18.7548 18.2791 18.6669 18.5 18.6667ZM63.5 63.6667H18.5C18.2791 63.6664 18.0672 63.5785 17.911 63.4223C17.7548 63.2661 17.6669 63.0543 17.6667 62.8333V25.3333H64.3333V62.8333C64.3331 63.0543 64.2452 63.2661 64.089 63.4223C63.9328 63.5785 63.7209 63.6664 63.5 63.6667Z" fill="#00C2E9"/>
            <path d="M26.8332 21.9999C27.2934 21.9999 27.6665 21.6268 27.6665 21.1666C27.6665 20.7063 27.2934 20.3333 26.8332 20.3333C26.373 20.3333 25.9999 20.7063 25.9999 21.1666C25.9999 21.6268 26.373 21.9999 26.8332 21.9999Z" fill="#00C2E9"/>
            <path d="M23.5 21.9999C23.9602 21.9999 24.3333 21.6268 24.3333 21.1666C24.3333 20.7063 23.9602 20.3333 23.5 20.3333C23.0397 20.3333 22.6666 20.7063 22.6666 21.1666C22.6666 21.6268 23.0397 21.9999 23.5 21.9999Z" fill="#00C2E9"/>
            <path d="M20.1666 21.9999C20.6268 21.9999 20.9999 21.6268 20.9999 21.1666C20.9999 20.7063 20.6268 20.3333 20.1666 20.3333C19.7063 20.3333 19.3333 20.7063 19.3333 21.1666C19.3333 21.6268 19.7063 21.9999 20.1666 21.9999Z" fill="#00C2E9"/>
            <path d="M60.1667 28.6666H21.8333C21.6123 28.6666 21.4004 28.7544 21.2441 28.9107C21.0878 29.067 21 29.2789 21 29.5V42.8333C21 43.0543 21.0878 43.2663 21.2441 43.4225C21.4004 43.5788 21.6123 43.6666 21.8333 43.6666H60.1667C60.3877 43.6666 60.5996 43.5788 60.7559 43.4225C60.9122 43.2663 61 43.0543 61 42.8333V29.5C61 29.2789 60.9122 29.067 60.7559 28.9107C60.5996 28.7544 60.3877 28.6666 60.1667 28.6666ZM46.5051 41.0067L48.5 39.0117L51.4882 42H47.25L46.5051 41.0067ZM45.1667 42H38.5L41.8333 37.5555L45.1667 42ZM36.4167 42H30.1005L34.3333 35.9531L37.5284 40.5176L36.4167 42ZM59.3333 42H53.8451L49.0893 37.244C49.0119 37.1666 48.92 37.1052 48.8189 37.0634C48.7178 37.0215 48.6094 36.9999 48.5 36.9999C48.3906 36.9999 48.2822 37.0215 48.1811 37.0634C48.08 37.1052 47.9881 37.1666 47.9107 37.244L45.4949 39.6599L42.5 35.6666C42.4224 35.5631 42.3217 35.4791 42.206 35.4213C42.0903 35.3634 41.9627 35.3333 41.8333 35.3333C41.704 35.3333 41.5764 35.3634 41.4607 35.4213C41.3449 35.4791 41.2443 35.5631 41.1667 35.6666L38.5807 39.1145L35.016 34.022C34.9392 33.9123 34.837 33.8226 34.7182 33.7608C34.5993 33.6989 34.4673 33.6666 34.3333 33.6666C34.1993 33.6666 34.0673 33.6989 33.9485 33.7608C33.8296 33.8226 33.7275 33.9123 33.6506 34.022L28.0661 42H22.6667V30.3333H59.3333V42Z" fill="#00C2E9"/>
            <path d="M46 35.3333C46.4602 35.3333 46.8333 34.9602 46.8333 34.5C46.8333 34.0397 46.4602 33.6666 46 33.6666C45.5397 33.6666 45.1666 34.0397 45.1666 34.5C45.1666 34.9602 45.5397 35.3333 46 35.3333Z" fill="#00C2E9"/>
            <path d="M44.3333 47C44.7936 47 45.1667 46.6269 45.1667 46.1666C45.1667 45.7064 44.7936 45.3333 44.3333 45.3333C43.8731 45.3333 43.5 45.7064 43.5 46.1666C43.5 46.6269 43.8731 47 44.3333 47Z" fill="#00C2E9"/>
            <path d="M41 47C41.4602 47 41.8333 46.6269 41.8333 46.1666C41.8333 45.7064 41.4602 45.3333 41 45.3333C40.5397 45.3333 40.1666 45.7064 40.1666 46.1666C40.1666 46.6269 40.5397 47 41 47Z" fill="#00C2E9"/>
            <path d="M37.6667 47C38.1269 47 38.5 46.6269 38.5 46.1666C38.5 45.7064 38.1269 45.3333 37.6667 45.3333C37.2065 45.3333 36.8334 45.7064 36.8334 46.1666C36.8334 46.6269 37.2065 47 37.6667 47Z" fill="#00C2E9"/>
            <path d="M31.8333 48.6667H21.8333C21.6123 48.6667 21.4004 48.7545 21.2441 48.9108C21.0878 49.067 21 49.279 21 49.5V56.1667C21 56.3877 21.0878 56.5997 21.2441 56.7559C21.4004 56.9122 21.6123 57 21.8333 57H31.8333C32.0543 57 32.2663 56.9122 32.4226 56.7559C32.5789 56.5997 32.6667 56.3877 32.6667 56.1667V49.5C32.6667 49.279 32.5789 49.067 32.4226 48.9108C32.2663 48.7545 32.0543 48.6667 31.8333 48.6667ZM31 50.3334V55.0995L27.3542 52.1828C27.2064 52.0645 27.0227 52 26.8333 52C26.644 52 26.4603 52.0645 26.3125 52.1828L22.6667 55.0995V50.3334H31ZM28.6244 55.3334H25.0423L26.8333 53.9005L28.6244 55.3334Z" fill="#00C2E9"/>
            <path d="M46.0001 48.6667H36.0001C35.7791 48.6667 35.5671 48.7545 35.4108 48.9108C35.2545 49.067 35.1667 49.279 35.1667 49.5V56.1667C35.1667 56.3877 35.2545 56.5997 35.4108 56.7559C35.5671 56.9122 35.7791 57 36.0001 57H46.0001C46.2211 57 46.4331 56.9122 46.5893 56.7559C46.7456 56.5997 46.8334 56.3877 46.8334 56.1667V49.5C46.8334 49.279 46.7456 49.067 46.5893 48.9108C46.4331 48.7545 46.2211 48.6667 46.0001 48.6667ZM45.1667 50.3334V55.0995L41.5209 52.1828C41.3731 52.0645 41.1894 52 41.0001 52C40.8107 52 40.627 52.0645 40.4792 52.1828L36.8334 55.0995V50.3334H45.1667ZM42.7911 55.3334H39.209L41.0001 53.9005L42.7911 55.3334Z" fill="#00C2E9"/>
            <path d="M60.1666 48.6667H50.1666C49.9456 48.6667 49.7336 48.7545 49.5773 48.9108C49.421 49.067 49.3333 49.279 49.3333 49.5V56.1667C49.3333 56.3877 49.421 56.5997 49.5773 56.7559C49.7336 56.9122 49.9456 57 50.1666 57H60.1666C60.3876 57 60.5996 56.9122 60.7558 56.7559C60.9121 56.5997 60.9999 56.3877 60.9999 56.1667V49.5C60.9999 49.279 60.9121 49.067 60.7558 48.9108C60.5996 48.7545 60.3876 48.6667 60.1666 48.6667ZM59.3333 50.3334V55.0995L55.6874 52.1828C55.5396 52.0645 55.3559 52 55.1666 52C54.9772 52 54.7936 52.0645 54.6458 52.1828L50.9999 55.0995V50.3334H59.3333ZM56.9576 55.3334H53.3755L55.1666 53.9005L56.9576 55.3334Z" fill="#00C2E9"/>
            <path d="M30.1666 58.6666H23.5C23.2789 58.6666 23.067 58.7544 22.9107 58.9107C22.7544 59.067 22.6666 59.2789 22.6666 59.5C22.6666 59.721 22.7544 59.9329 22.9107 60.0892C23.067 60.2455 23.2789 60.3333 23.5 60.3333H30.1666C30.3876 60.3333 30.5996 60.2455 30.7559 60.0892C30.9122 59.9329 31 59.721 31 59.5C31 59.2789 30.9122 59.067 30.7559 58.9107C30.5996 58.7544 30.3876 58.6666 30.1666 58.6666Z" fill="#00C2E9"/>
            <path d="M44.3334 58.6666H37.6667C37.4457 58.6666 37.2337 58.7544 37.0775 58.9107C36.9212 59.067 36.8334 59.2789 36.8334 59.5C36.8334 59.721 36.9212 59.9329 37.0775 60.0892C37.2337 60.2455 37.4457 60.3333 37.6667 60.3333H44.3334C44.5544 60.3333 44.7663 60.2455 44.9226 60.0892C45.0789 59.9329 45.1667 59.721 45.1667 59.5C45.1667 59.2789 45.0789 59.067 44.9226 58.9107C44.7663 58.7544 44.5544 58.6666 44.3334 58.6666Z" fill="#00C2E9"/>
            <path d="M58.4999 58.6666H51.8332C51.6122 58.6666 51.4002 58.7544 51.244 58.9107C51.0877 59.067 50.9999 59.2789 50.9999 59.5C50.9999 59.721 51.0877 59.9329 51.244 60.0892C51.4002 60.2455 51.6122 60.3333 51.8332 60.3333H58.4999C58.7209 60.3333 58.9329 60.2455 59.0891 60.0892C59.2454 59.9329 59.3332 59.721 59.3332 59.5C59.3332 59.2789 59.2454 59.067 59.0891 58.9107C58.9329 58.7544 58.7209 58.6666 58.4999 58.6666Z" fill="#00C2E9"/>
        </svg>
    );
};

export default WebDesignIcon;
