import React, {useState, useRef} from 'react';
import {Link} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';
import {Menu} from 'antd';
import styles from './MobileMenu.module.less';
import useOutsideListener from '../../hooks/useOutsideListener';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import CheckIcon from '../icons/CheckIcon';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const {SubMenu} = Menu;

const MobileMenu = ({handleChangeLanguage, selectedKeys}) => {
    const {formatMessage, locale} = useIntl();
    const languageMenuItemRef = useRef(null);
    const menuTitleRef = useRef(null);
    const containerRef = useRef(null);
    const [openKeys, setOpenKeys] = useState([]);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const selectedLanguage = typeof localStorage !== 'undefined' ? localStorage.getItem('selectedLanguage') : 'en';
    const mobile = useBreakpoint().xs;

    useOutsideListener([languageMenuItemRef, menuTitleRef], () => {
        if (openKeys.length > 0) {
            setOpenKeys([]);
        }
    });

    const menuItems = [
        <Menu.Item key="/" onClick={() => setOpenKeys([])}>
            <Link to={`/${locale}/`}>{formatMessage({id: 'nav.label.home'})}</Link>
        </Menu.Item>,
        <Menu.Item key="/about" onClick={() => setOpenKeys([])}>
            <Link to={`/${locale}/about`}>{formatMessage({id: 'nav.label.about'})}</Link>
        </Menu.Item>,
        <Menu.Item key="/services" onClick={() => setOpenKeys([])}>
            <Link to={`/${locale}/services`}>{formatMessage({id: 'nav.label.services'})}</Link>
        </Menu.Item>,
        <Menu.Item key="/magazine" onClick={() => setOpenKeys([])}>
            <Link to={`/${locale}/magazine`}>{formatMessage({id: 'nav.label.magazine'})}</Link>
        </Menu.Item>,
        // <Menu.Item key="/product" onClick={() => setOpenKeys([])}>
        //     <Link to={`/${locale}/product`}>{formatMessage({id: 'nav.label.products'})}</Link>
        // </Menu.Item>,
        <Menu.Item key="/jobs" onClick={() => setOpenKeys([])}>
            <Link to={`/${locale}/jobs`}>{formatMessage({id: 'nav.label.jobs'})}</Link>
        </Menu.Item>,
        <Menu.Item key="/contact" onClick={() => setOpenKeys([])}>
            <Link to={`/${locale}/contact`}>{formatMessage({id: 'nav.label.contact'})}</Link>
        </Menu.Item>,
        <Menu.Item key="Language" onClick={() => setIsSubMenuOpen(true)}>
            <div ref={languageMenuItemRef} className={styles.submenuItem}>
                <span className={styles.submenuItemTitle}>
                    {formatMessage({id: 'nav.label.language'})}
                </span>
                <div className={styles.adornment}>
                    {selectedLanguage === 'de' && <span>Deutsch</span>}
                    {selectedLanguage === 'en' && <span>English</span>}
                    <ArrowDownIcon />
                </div>
            </div>
        </Menu.Item>
    ]

    const subMenuItems = [
        <Menu.Item key="SubLanguage" onClick={() => setIsSubMenuOpen(false)}>
            <div ref={languageMenuItemRef} className={styles.submenuTitle}>
                <ArrowDownIcon />
                <span>{formatMessage({id: 'nav.label.language'})}</span>
            </div>
        </Menu.Item>,
        <Menu.Item key="Deutsch" onClick={() => handleChangeLanguage('de')}>
            <div className={styles.submenuItem}>
                <span>Deutsch</span>
                {selectedLanguage === 'de' && <CheckIcon />}
            </div>
        </Menu.Item>,
        <Menu.Item key="English" onClick={() => handleChangeLanguage('en')}>
            <div className={styles.submenuItem}>
                <span>English</span>
                {selectedLanguage === 'en' && <CheckIcon />}
            </div>
        </Menu.Item>
    ]

    return (
        <div className={styles.container} ref={containerRef}>
            <Menu
                mode="horizontal"
                triggerSubMenuAction="click"
                getPopupContainer={() => containerRef.current}
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                motion={{
                    motionName: 'slide-up',
                    onLeaveEnd: () => {
                        if (openKeys.length === 0) {
                            setIsSubMenuOpen(false);
                        }
                    }
                }}
            >
                <SubMenu
                    title={<div ref={menuTitleRef}>{formatMessage({id: 'nav.label.menu'})}</div>}
                    onTitleClick={({key}) => {
                        if (openKeys.length > 0) {
                            setOpenKeys([]);
                        } else {
                            setOpenKeys([key]);
                        }
                    }}
                    popupOffset={[mobile ? 100 : 0, 11]}
                >
                    {!isSubMenuOpen ? menuItems : subMenuItems}
                </SubMenu>
            </Menu>
        </div>
    );
}

export default MobileMenu;
