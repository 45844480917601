import {useEffect} from 'react';

function useOutsideListener(refs, onClickOutside) {
    useEffect(() => {
        function handleClickOutside(event) {
            const dontClose = refs.some((ref) => {
                return ref.current && (ref.current.contains(event.target) || ref.current === event.target);
            })
            if (!dontClose) {
                onClickOutside();
            }
        }
        // Bind the event listener
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('scroll', handleClickOutside);
        window.addEventListener('resize', onClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('scroll', handleClickOutside);
            window.removeEventListener('resize', onClickOutside);
        };
    }, [refs, onClickOutside]);
}

export default useOutsideListener;
