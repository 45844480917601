import React from 'react';

const AppSoftwareDevelopmentActiveIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="currentColor"/>
            <path d="M39.3749 46.875C39.3747 47.0407 39.3088 47.1996 39.1916 47.3167C39.0744 47.4339 38.9156 47.4998 38.7499 47.5H21.2499C21.0842 47.4998 20.9253 47.4339 20.8081 47.3167C20.691 47.1996 20.6251 47.0407 20.6249 46.875V40H19.3749V46.875C19.3754 47.3721 19.5732 47.8487 19.9247 48.2002C20.2762 48.5517 20.7528 48.7494 21.2499 48.75H38.7499C39.247 48.7494 39.7236 48.5517 40.0751 48.2002C40.4266 47.8487 40.6243 47.3721 40.6249 46.875V33.75H39.3749V46.875Z" fill="white"/>
            <path d="M20.6249 13.125C20.6251 12.9593 20.691 12.8004 20.8081 12.6833C20.9253 12.5661 21.0842 12.5002 21.2499 12.5H38.7499C38.9156 12.5002 39.0744 12.5661 39.1916 12.6833C39.3088 12.8004 39.3747 12.9593 39.3749 13.125V22.5H40.6249V13.125C40.6243 12.6279 40.4266 12.1513 40.0751 11.7998C39.7236 11.4483 39.247 11.2506 38.7499 11.25H21.2499C20.7528 11.2506 20.2762 11.4483 19.9247 11.7998C19.5732 12.1513 19.3754 12.6279 19.3749 13.125V18.75H20.6249V13.125Z" fill="white"/>
            <path d="M38.7499 41.875V33.75H37.4999V41.25H22.4999V40H21.2499V41.875C21.2499 41.9571 21.266 42.0383 21.2974 42.1142C21.3289 42.19 21.3749 42.2589 21.4329 42.3169C21.491 42.375 21.5599 42.421 21.6357 42.4524C21.7115 42.4838 21.7928 42.5 21.8749 42.5H38.1249C38.207 42.5 38.2882 42.4838 38.3641 42.4524C38.4399 42.421 38.5088 42.375 38.5668 42.3169C38.6249 42.2589 38.6709 42.19 38.7023 42.1142C38.7337 42.0383 38.7499 41.9571 38.7499 41.875Z" fill="white"/>
            <path d="M38.7499 22.5002V16.8752C38.7499 16.7932 38.7337 16.7119 38.7023 16.6361C38.6709 16.5602 38.6249 16.4913 38.5668 16.4333C38.5088 16.3753 38.4399 16.3292 38.3641 16.2978C38.2882 16.2664 38.207 16.2502 38.1249 16.2502H21.8749C21.7928 16.2502 21.7115 16.2664 21.6357 16.2978C21.5599 16.3292 21.491 16.3753 21.4329 16.4333C21.3749 16.4913 21.3289 16.5602 21.2974 16.6361C21.266 16.7119 21.2499 16.7932 21.2499 16.8752V18.7502H22.4999V17.5002H37.4999V22.5002H38.7499Z" fill="white"/>
            <path d="M28.7499 13.7498H32.4999V14.9998H28.7499V13.7498Z" fill="white"/>
            <path d="M27.4999 14.9998C27.8451 14.9998 28.1249 14.7199 28.1249 14.3748C28.1249 14.0296 27.8451 13.7498 27.4999 13.7498C27.1547 13.7498 26.8749 14.0296 26.8749 14.3748C26.8749 14.7199 27.1547 14.9998 27.4999 14.9998Z" fill="white"/>
            <path d="M28.125 45C28.125 45.3708 28.235 45.7334 28.441 46.0417C28.647 46.35 28.9399 46.5904 29.2825 46.7323C29.6251 46.8742 30.0021 46.9113 30.3658 46.839C30.7295 46.7666 31.0636 46.588 31.3258 46.3258C31.588 46.0636 31.7666 45.7295 31.839 45.3658C31.9113 45.0021 31.8742 44.6251 31.7323 44.2825C31.5904 43.9399 31.35 43.647 31.0417 43.441C30.7334 43.235 30.3708 43.125 30 43.125C29.5029 43.1256 29.0263 43.3233 28.6748 43.6748C28.3233 44.0263 28.1256 44.5029 28.125 45ZM30.625 45C30.625 45.1236 30.5883 45.2444 30.5197 45.3472C30.451 45.45 30.3534 45.5301 30.2392 45.5774C30.125 45.6247 29.9993 45.6371 29.8781 45.613C29.7568 45.5889 29.6455 45.5294 29.5581 45.4419C29.4706 45.3545 29.4111 45.2432 29.387 45.1219C29.3629 45.0007 29.3753 44.875 29.4226 44.7608C29.4699 44.6466 29.55 44.549 29.6528 44.4803C29.7555 44.4117 29.8764 44.375 30 44.375C30.1657 44.3752 30.3246 44.4411 30.4417 44.5583C30.5589 44.6754 30.6248 44.8343 30.625 45Z" fill="white"/>
            <path d="M26.25 36.875V33.3839L28.5669 31.0669C28.6543 30.9795 28.7139 30.8682 28.738 30.7469C28.7621 30.6257 28.7497 30.5 28.7024 30.3858C28.6551 30.2716 28.575 30.174 28.4722 30.1053C28.3695 30.0366 28.2486 30 28.125 30H13.125C12.6279 30.0006 12.1513 30.1983 11.7998 30.5498C11.4483 30.9013 11.2506 31.3779 11.25 31.875V36.875C11.2506 37.3721 11.4483 37.8487 11.7998 38.2002C12.1513 38.5517 12.6279 38.7494 13.125 38.75H24.375C24.8721 38.7494 25.3487 38.5517 25.7002 38.2002C26.0517 37.8487 26.2494 37.3721 26.25 36.875ZM12.5 36.875V31.875C12.5002 31.7093 12.5661 31.5504 12.6833 31.4333C12.8004 31.3161 12.9593 31.2502 13.125 31.25H26.6161L25.1831 32.6831C25.125 32.7411 25.079 32.81 25.0476 32.8858C25.0162 32.9617 25 33.0429 25 33.125V36.875C24.9998 37.0407 24.9339 37.1996 24.8167 37.3167C24.6996 37.4339 24.5407 37.4998 24.375 37.5H13.125C12.9593 37.4998 12.8004 37.4339 12.6833 37.3167C12.5661 37.1996 12.5002 37.0407 12.5 36.875Z" fill="white"/>
            <path d="M13.125 28.7502H28.125C28.2486 28.7502 28.3695 28.7136 28.4722 28.6449C28.575 28.5762 28.6551 28.4786 28.7024 28.3644C28.7497 28.2502 28.7621 28.1246 28.738 28.0033C28.7139 27.8821 28.6543 27.7707 28.5669 27.6833L26.25 25.3663V21.8752C26.2494 21.3781 26.0517 20.9016 25.7002 20.55C25.3487 20.1985 24.8721 20.0008 24.375 20.0002H13.125C12.6279 20.0008 12.1513 20.1985 11.7998 20.55C11.4483 20.9016 11.2506 21.3781 11.25 21.8752V26.8752C11.2506 27.3723 11.4483 27.8489 11.7998 28.2004C12.1513 28.5519 12.6279 28.7497 13.125 28.7502ZM12.5 21.8752C12.5002 21.7095 12.5661 21.5507 12.6833 21.4335C12.8004 21.3163 12.9593 21.2504 13.125 21.2502H24.375C24.5407 21.2504 24.6996 21.3163 24.8167 21.4335C24.9339 21.5507 24.9998 21.7095 25 21.8752V25.6252C25 25.7073 25.0162 25.7886 25.0476 25.8644C25.079 25.9402 25.125 26.0091 25.1831 26.0672L26.6161 27.5002H13.125C12.9593 27.5001 12.8004 27.4341 12.6833 27.317C12.5661 27.1998 12.5002 27.0409 12.5 26.8752V21.8752Z" fill="white"/>
            <path d="M46.8751 23.7502H35.6251C35.128 23.7508 34.6514 23.9485 34.2999 24.3C33.9484 24.6516 33.7507 25.1281 33.7501 25.6252V29.1163L31.4332 31.4333C31.3458 31.5207 31.2863 31.6321 31.2621 31.7533C31.238 31.8746 31.2504 32.0002 31.2977 32.1144C31.345 32.2286 31.4251 32.3262 31.5279 32.3949C31.6307 32.4636 31.7515 32.5002 31.8751 32.5002H46.8751C47.3722 32.4997 47.8488 32.3019 48.2003 31.9504C48.5518 31.5989 48.7496 31.1223 48.7501 30.6252V25.6252C48.7496 25.1281 48.5518 24.6516 48.2003 24.3C47.8488 23.9485 47.3722 23.7508 46.8751 23.7502ZM47.5001 30.6252C47.4999 30.7909 47.434 30.9498 47.3169 31.067C47.1997 31.1841 47.0408 31.2501 46.8751 31.2502H33.384L34.8171 29.8172C34.8751 29.7591 34.9211 29.6902 34.9526 29.6144C34.984 29.5386 35.0001 29.4573 35.0001 29.3752V25.6252C35.0003 25.4595 35.0662 25.3007 35.1834 25.1835C35.3006 25.0663 35.4594 25.0004 35.6251 25.0002H46.8751C47.0408 25.0004 47.1997 25.0663 47.3169 25.1835C47.434 25.3007 47.4999 25.4595 47.5001 25.6252V30.6252Z" fill="white"/>
            <path d="M15 22.5H17.5V23.75H15V22.5Z" fill="white"/>
            <path d="M18.75 22.5H23.75V23.75H18.75V22.5Z" fill="white"/>
            <path d="M13.7499 24.9998H16.2499V26.2498H13.7499V24.9998Z" fill="white"/>
            <path d="M17.4999 24.9998H21.2499V26.2498H17.4999V24.9998Z" fill="white"/>
            <path d="M37.5 26.25H41.25V27.5H37.5V26.25Z" fill="white"/>
            <path d="M42.5001 26.25H46.2501V27.5H42.5001V26.25Z" fill="white"/>
            <path d="M36.2499 28.7498H43.7499V29.9998H36.2499V28.7498Z" fill="white"/>
            <path d="M15 32.4998H17.5V33.7498H15V32.4998Z" fill="white"/>
            <path d="M18.75 32.4998H21.25V33.7498H18.75V32.4998Z" fill="white"/>
            <path d="M22.5 32.4998H23.75V33.7498H22.5V32.4998Z" fill="white"/>
            <path d="M13.7499 35.0002H18.7499V36.2502H13.7499V35.0002Z" fill="white"/>
            <path d="M20.0001 35.0002H23.7501V36.2502H20.0001V35.0002Z" fill="white"/>
        </svg>
    );
};

export default AppSoftwareDevelopmentActiveIcon;
