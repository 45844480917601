import React from 'react';
import PropTypes from 'prop-types';
import {Layout as AntdLayout} from 'antd';
import MIAHeader from "./header/Header";
import MIAFooter from "./footer/Footer";
import '../less/layout.less';
// This order is intended: CookiesWarning less should be applied AFTER the layout.less
import CookiesWarning from './cookies-warning/CookiesWarning';
import {graphql} from 'gatsby';

export default class Layout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {Content, Footer} = AntdLayout;

        return (
            <>
                <AntdLayout>
                    <MIAHeader />
                    <Content className="app-content">
                        <main>{this.props.children}</main>
                    </Content>
                    <Footer>
                        <MIAFooter data={this.props.data} />
                    </Footer>
                </AntdLayout>
                <CookiesWarning/>
            </>
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export const pageQuery = graphql`
  query($locale: String) {
    allContentfulArticle(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          heroImage { 
            fixed(width: 600, toFormat: PNG) {
              src
            }
          }
          category { color }
        }
      }
    }
  }
`;
