import React from 'react';
import {Link} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';
import {List, Typography, BackTop, Button} from 'antd';
import styles from './Footer.module.less';
import useGlobalState from '../../hooks/useGlobalState';
import competenciesConfig from '../../configs/competencies-config'
import ArrowUpIcon from '../icons/ArrowUpIcon';

const MIAFooter = ({data}) => {
    const {Text} = Typography;
    const {locale, formatMessage} = useIntl();
    const globalState = useGlobalState();
    const articles = data?.allContentfulArticle?.edges.map(({node}) => node);
    const jobs = data?.allContentfulVacancy?.edges.map(({node}) => node);
    // const products = data?.allContentfulStory?.edges.map(({node}) => node);


    return (
        <React.Fragment>
            <div className={`${styles.footer} container`}>
                <div className={styles.footerGrid}>
                    <div className={styles.footerGridItem}>
                        <List split={false}>
                            <List.Item>
                                <Link to={`/${locale}/about`}>
                                    <p className="ant-typography-paragraph text-semi-bold">{formatMessage({id: 'nav.label.about'})}</p>
                                </Link>
                            </List.Item>
                            <List.Item>
                                <Link to={`/${locale}/contact`}>{formatMessage({id: 'footer.nav.contact'})}</Link>
                            </List.Item>
                            <List.Item>
                                <Link to={`/${locale}/legal`}>{formatMessage({id: 'footer.nav.imprint'})}</Link>
                            </List.Item>
                            <List.Item>
                                <Link to={`/${locale}/legal`}>{formatMessage({id: 'footer.nav.data-regulation'})}</Link>
                            </List.Item>
                            <List.Item>
                                <Link to={`/${locale}/legal#cookies`}>{formatMessage({id: 'footer.nav.cookie-policy'})}</Link>
                            </List.Item>
                        </List>
                    </div>
                    <div className={styles.footerGridItem}>
                        <List split={false}>
                            <List.Item>
                                <Link to={`/${locale}/services`}>
                                    <p className="ant-typography-paragraph text-semi-bold">{formatMessage({id: 'nav.label.services'})}</p>
                                </Link>
                            </List.Item>
                            {competenciesConfig.map((service) => (
                                <List.Item>
                                    <Link
                                        to={`/${locale}/services`}
                                        onClick={() => globalState.setActiveCompetenciesTab(service.id)}
                                    >
                                        {formatMessage({id: service.label})}
                                    </Link>
                                </List.Item>
                            ))}
                        </List>
                    </div>
                    {/*<div className={styles.footerGridItem}>*/}
                    {/*    <List split={false}>*/}
                    {/*        <List.Item>*/}
                    {/*            <Link to={`/${locale}/products`}>*/}
                    {/*                <p className="ant-typography-paragraph text-semi-bold">{formatMessage({id: 'nav.label.products'})}</p>*/}
                    {/*            </Link>*/}
                    {/*        </List.Item>*/}
                    {/*        {products?.slice(0, 6).map((product) => (*/}
                    {/*            <List.Item>*/}
                    {/*                <Link*/}
                    {/*                    to={`/${locale}/product/${product.slug}`}*/}
                    {/*                >*/}
                    {/*                    {product.title}*/}
                    {/*                </Link>*/}
                    {/*            </List.Item>*/}
                    {/*        ))}*/}
                    {/*    </List>*/}
                    {/*</div>*/}
                    <div className={styles.footerGridItem}>
                        <List split={false}>
                            <List.Item>
                                <Link to={`/${locale}/magazine`}>
                                    <p className="ant-typography-paragraph text-semi-bold">{formatMessage({id: 'nav.label.magazine'})}</p>
                                </Link>
                            </List.Item>
                            {articles?.slice(0, 6).map((article) => (
                                <List.Item>
                                    <Link
                                        to={`/${locale}/magazine/${article.slug}`}
                                    >
                                        {article.title}
                                    </Link>
                                </List.Item>
                            ))}
                        </List>
                    </div>
                    <div className={styles.footerGridItem}>
                        <List split={false}>
                            <List.Item>
                                <Link to={`/${locale}/jobs`}>
                                    <p className="ant-typography-paragraph text-semi-bold">{formatMessage({id: 'nav.label.jobs'})}</p>
                                </Link>
                            </List.Item>
                            {jobs?.slice(0, 6).map((job) => (
                                <List.Item>
                                    <Link
                                        to={`/${locale}/jobs/${job.slug}`}
                                    >
                                        {job.title}
                                    </Link>
                                </List.Item>
                            ))}
                        </List>
                    </div>
                </div>
                <Text className={styles.footerText}>
                    <Link to={`/${locale}/legal`} className={styles.link}>copyright © <strong>IAM MIA</strong> 2007 - {new Date().getFullYear()}. all rights reserved.</Link>
                </Text>
                <BackTop className={styles.backToTopWrapper}>
                    <Button type="primary" shape="circle" icon={<ArrowUpIcon />} className={styles.backToTop} />
                </BackTop>
            </div>
        </React.Fragment>
    )
};

export default MIAFooter;
