import React from 'react';

const ProjectManagementIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M41 81C63.0914 81 81 63.0914 81 41C81 18.9086 63.0914 1 41 1C18.9086 1 1 18.9086 1 41C1 63.0914 18.9086 81 41 81Z" stroke="#00C2E9" strokeWidth="2"/>
            <path d="M61.3333 64.125C61.3333 64.2401 61.2401 64.3334 61.125 64.3334H38V66H61.125C62.16 65.9988 62.9987 65.16 63 64.125V34.3334H61.3333V64.125Z" fill="#00C2E9"/>
            <path d="M61.125 16H30.7084C29.6734 16.0012 28.8346 16.84 28.8334 17.875V32.6667H30.5V17.875C30.5 17.7599 30.5933 17.6667 30.7084 17.6667H61.125C61.2401 17.6667 61.3334 17.7599 61.3334 17.875V32.6667H63V17.875C62.9988 16.84 62.16 16.0012 61.125 16Z" fill="#00C2E9"/>
            <path d="M58.2441 36.244L53 41.4882L51.0893 39.5774L49.9108 40.7559L52.4108 43.2559C52.7362 43.5813 53.2638 43.5813 53.5893 43.2559L59.4226 37.4226L58.2441 36.244Z" fill="#00C2E9"/>
            <path d="M47.1666 46C47.1666 46.4602 47.5398 46.8333 48 46.8333H56.3333V45.1667H48.8333V37.6667H54.6666V36H48C47.5398 36 47.1666 36.3731 47.1666 36.8333V46Z" fill="#00C2E9"/>
            <path d="M51.0893 53.744L49.9108 54.9226L52.4108 57.4226C52.7362 57.748 53.2638 57.748 53.5893 57.4226L59.4226 51.5892L58.2441 50.4107L53 55.6549L51.0893 53.744Z" fill="#00C2E9"/>
            <path d="M47.1666 60.1667C47.1666 60.6269 47.5398 61 48 61H56.3333V59.3334H48.8333V51.8334H54.6666V50.1667H48C47.5398 50.1667 47.1666 50.5398 47.1666 51V60.1667Z" fill="#00C2E9"/>
            <path d="M58.2441 21.244L53 26.4882L51.0893 24.5774L49.9108 25.7559L52.4108 28.2559C52.7362 28.5813 53.2638 28.5813 53.5893 28.2559L59.4226 22.4226L58.2441 21.244Z" fill="#00C2E9"/>
            <path d="M47.1666 21.8333V31C47.1666 31.4602 47.5398 31.8333 48 31.8333H56.3333V30.1667H48.8333V22.6667H54.6666V21H48C47.5398 21 47.1666 21.3731 47.1666 21.8333Z" fill="#00C2E9"/>
            <path d="M36.0893 24.5774L34.9108 25.7559L37.4108 28.2559C37.7362 28.5813 38.2638 28.5813 38.5893 28.2559L44.4226 22.4226L43.2441 21.244L38 26.4882L36.0893 24.5774Z" fill="#00C2E9"/>
            <path d="M32.1666 21.8333V31C32.1666 31.4602 32.5398 31.8333 33 31.8333H41.3333V30.1667H33.8333V22.6667H39.6666V21H33C32.5398 21 32.1666 21.3731 32.1666 21.8333Z" fill="#00C2E9"/>
            <path d="M44.6667 50.1667C44.6667 41.4362 37.5639 34.3334 28.8333 34.3334C20.1028 34.3334 13 41.4362 13 50.1667H14.6667C14.6667 42.3552 21.0218 36 28.8333 36C36.6449 36 43 42.3552 43 50.1667C43 57.9782 36.6449 64.3334 28.8333 64.3334C21.6221 64.324 15.5648 58.9069 14.7535 51.7414L13.0973 51.9254C14.0025 59.9348 20.7729 65.9905 28.8333 66C37.5639 66 44.6667 58.8972 44.6667 50.1667Z" fill="#00C2E9"/>
            <path d="M28 51.4328L24.1459 54.5161L25.1875 55.8176L29.3542 52.4843C29.5518 52.326 29.6668 52.0866 29.6667 51.8333V43.5H28V51.4328Z" fill="#00C2E9"/>
            <path d="M29.6667 39.3651C30.4333 39.4234 31.1915 39.5637 31.9282 39.7835L32.4051 38.1864C25.7783 36.2028 18.7981 39.9668 16.8145 46.5935C14.8308 53.2203 18.5948 60.2005 25.2216 62.1841C31.8483 64.1678 38.8286 60.4038 40.8122 53.7771C42.5846 47.8563 39.7732 41.5279 34.1917 38.8739L33.475 40.3786C34.3415 40.7915 35.149 41.3184 35.8761 41.9451L34.726 43.0952L35.9044 44.2738L37.0521 43.1265C38.5457 44.8714 39.4495 47.0438 39.6341 49.3333H38V51H39.6354C39.46 53.2924 38.5567 55.4687 37.0573 57.2116L35.9049 56.0591L34.7265 57.2378L35.8788 58.3902C34.1358 59.8898 31.9593 60.7932 29.6667 60.9687V59.3333H28V60.9687C25.7077 60.7933 23.5314 59.89 21.7885 58.3907L22.9407 57.2383L21.7623 56.0597L20.6099 57.2116C19.1105 55.4687 18.2072 53.2924 18.0318 51H19.6667V49.3333H18.0318C18.2072 47.0409 19.1105 44.8646 20.6099 43.1216L21.7623 44.2741L22.9407 43.0955L21.7885 41.9431C23.5314 40.4438 25.7077 39.5405 28 39.3651V41H29.6667V39.3651Z" fill="#00C2E9"/>
        </svg>
    );
};

export default ProjectManagementIcon;
