import React from 'react';

const WebDesignActiveIcon = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="currentColor"/>
            <path d="M46.875 12H13.125C12.6279 12.0006 12.1513 12.1983 11.7998 12.5498C11.4483 12.9013 11.2506 13.3779 11.25 13.875V46.375C11.2506 46.8721 11.4483 47.3487 11.7998 47.7002C12.1513 48.0517 12.6279 48.2494 13.125 48.25H46.875C47.3721 48.2494 47.8487 48.0517 48.2002 47.7002C48.5517 47.3487 48.7494 46.8721 48.75 46.375V13.875C48.7494 13.3779 48.5517 12.9013 48.2002 12.5498C47.8487 12.1983 47.3721 12.0006 46.875 12ZM13.125 13.25H46.875C47.0407 13.2502 47.1996 13.3161 47.3167 13.4333C47.4339 13.5504 47.4998 13.7093 47.5 13.875V17H12.5V13.875C12.5002 13.7093 12.5661 13.5504 12.6833 13.4333C12.8004 13.3161 12.9593 13.2502 13.125 13.25ZM46.875 47H13.125C12.9593 46.9998 12.8004 46.9339 12.6833 46.8167C12.5661 46.6996 12.5002 46.5407 12.5 46.375V18.25H47.5V46.375C47.4998 46.5407 47.4339 46.6996 47.3167 46.8167C47.1996 46.9339 47.0407 46.9998 46.875 47Z" fill="white"/>
            <path d="M19.375 15.7498C19.7202 15.7498 20 15.4699 20 15.1248C20 14.7796 19.7202 14.4998 19.375 14.4998C19.0298 14.4998 18.75 14.7796 18.75 15.1248C18.75 15.4699 19.0298 15.7498 19.375 15.7498Z" fill="white"/>
            <path d="M16.876 15.7498C17.2212 15.7498 17.501 15.4699 17.501 15.1248C17.501 14.7796 17.2212 14.4998 16.876 14.4998C16.5308 14.4998 16.251 14.7796 16.251 15.1248C16.251 15.4699 16.5308 15.7498 16.876 15.7498Z" fill="white"/>
            <path d="M14.374 15.7498C14.7192 15.7498 14.999 15.4699 14.999 15.1248C14.999 14.7796 14.7192 14.4998 14.374 14.4998C14.0288 14.4998 13.749 14.7796 13.749 15.1248C13.749 15.4699 14.0288 15.7498 14.374 15.7498Z" fill="white"/>
            <path d="M44.375 20.7502H15.625C15.4592 20.7502 15.3003 20.8161 15.1831 20.9333C15.0658 21.0505 15 21.2095 15 21.3752V31.3752C15 31.541 15.0658 31.7 15.1831 31.8172C15.3003 31.9344 15.4592 32.0002 15.625 32.0002H44.375C44.5408 32.0002 44.6997 31.9344 44.8169 31.8172C44.9342 31.7 45 31.541 45 31.3752V21.3752C45 21.2095 44.9342 21.0505 44.8169 20.9333C44.6997 20.8161 44.5408 20.7502 44.375 20.7502ZM34.1288 30.0053L35.625 28.5091L37.8662 30.7502H34.6875L34.1288 30.0053ZM33.125 30.7502H28.125L30.625 27.4169L33.125 30.7502ZM26.5625 30.7502H21.8254L25 26.2151L27.3963 29.6384L26.5625 30.7502ZM43.75 30.7502H39.6338L36.067 27.1833C36.0089 27.1253 35.94 27.0792 35.8642 27.0478C35.7884 27.0164 35.7071 27.0002 35.625 27.0002C35.5429 27.0002 35.4616 27.0164 35.3858 27.0478C35.31 27.0792 35.2411 27.1253 35.183 27.1833L33.3712 28.9952L31.125 26.0002C31.0668 25.9226 30.9913 25.8596 30.9045 25.8162C30.8177 25.7728 30.722 25.7502 30.625 25.7502C30.528 25.7502 30.4323 25.7728 30.3455 25.8162C30.2587 25.8596 30.1832 25.9226 30.125 26.0002L28.1855 28.5862L25.512 24.7668C25.4544 24.6845 25.3778 24.6173 25.2886 24.5708C25.1995 24.5244 25.1005 24.5002 25 24.5002C24.8995 24.5002 24.8005 24.5244 24.7114 24.5708C24.6222 24.6173 24.5456 24.6845 24.488 24.7668L20.2996 30.7502H16.25V22.0002H43.75V30.7502Z" fill="white"/>
            <path d="M33.751 25.75C34.0962 25.75 34.376 25.4702 34.376 25.125C34.376 24.7798 34.0962 24.5 33.751 24.5C33.4058 24.5 33.126 24.7798 33.126 25.125C33.126 25.4702 33.4058 25.75 33.751 25.75Z" fill="white"/>
            <path d="M32.5 34.4998C32.8452 34.4998 33.125 34.2199 33.125 33.8748C33.125 33.5296 32.8452 33.2498 32.5 33.2498C32.1548 33.2498 31.875 33.5296 31.875 33.8748C31.875 34.2199 32.1548 34.4998 32.5 34.4998Z" fill="white"/>
            <path d="M30.001 34.4998C30.3462 34.4998 30.626 34.2199 30.626 33.8748C30.626 33.5296 30.3462 33.2498 30.001 33.2498C29.6558 33.2498 29.376 33.5296 29.376 33.8748C29.376 34.2199 29.6558 34.4998 30.001 34.4998Z" fill="white"/>
            <path d="M27.499 34.4998C27.8442 34.4998 28.124 34.2199 28.124 33.8748C28.124 33.5296 27.8442 33.2498 27.499 33.2498C27.1538 33.2498 26.874 33.5296 26.874 33.8748C26.874 34.2199 27.1538 34.4998 27.499 34.4998Z" fill="white"/>
            <path d="M23.125 35.7502H15.625C15.4592 35.7502 15.3003 35.8161 15.1831 35.9333C15.0658 36.0505 15 36.2095 15 36.3752V41.3752C15 41.541 15.0658 41.7 15.1831 41.8172C15.3003 41.9344 15.4592 42.0002 15.625 42.0002H23.125C23.2908 42.0002 23.4497 41.9344 23.5669 41.8172C23.6842 41.7 23.75 41.541 23.75 41.3752V36.3752C23.75 36.2095 23.6842 36.0505 23.5669 35.9333C23.4497 35.8161 23.2908 35.7502 23.125 35.7502ZM22.5 37.0002V40.5749L19.7656 38.3874C19.6548 38.2986 19.517 38.2502 19.375 38.2502C19.233 38.2502 19.0952 38.2986 18.9844 38.3874L16.25 40.5749V37.0002H22.5ZM20.7183 40.7502H18.0317L19.375 39.6756L20.7183 40.7502Z" fill="white"/>
            <path d="M44.374 35.7502H36.874C36.7083 35.7502 36.5493 35.8161 36.4321 35.9333C36.3149 36.0505 36.249 36.2095 36.249 36.3752V41.3752C36.249 41.541 36.3149 41.7 36.4321 41.8172C36.5493 41.9344 36.7083 42.0002 36.874 42.0002H44.374C44.5398 42.0002 44.6988 41.9344 44.816 41.8172C44.9332 41.7 44.999 41.541 44.999 41.3752V36.3752C44.999 36.2095 44.9332 36.0505 44.816 35.9333C44.6988 35.8161 44.5398 35.7502 44.374 35.7502ZM43.749 37.0002V40.5749L41.0146 38.3874C40.9038 38.2986 40.766 38.2502 40.624 38.2502C40.482 38.2502 40.3443 38.2986 40.2334 38.3874L37.499 40.5749V37.0002H43.749ZM41.9673 40.7502H39.2807L40.624 39.6756L41.9673 40.7502Z" fill="white"/>
            <path d="M21.876 43.25H16.876C16.7102 43.25 16.5512 43.3158 16.434 43.4331C16.3168 43.5503 16.251 43.7092 16.251 43.875C16.251 44.0408 16.3168 44.1997 16.434 44.3169C16.5512 44.4342 16.7102 44.5 16.876 44.5H21.876C22.0417 44.5 22.2007 44.4342 22.3179 44.3169C22.4351 44.1997 22.501 44.0408 22.501 43.875C22.501 43.7092 22.4351 43.5503 22.3179 43.4331C22.2007 43.3158 22.0417 43.25 21.876 43.25Z" fill="white"/>
            <path d="M32.499 43.25H27.499C27.3333 43.25 27.1743 43.3158 27.0571 43.4331C26.9399 43.5503 26.874 43.7092 26.874 43.875C26.874 44.0408 26.9399 44.1997 27.0571 44.3169C27.1743 44.4342 27.3333 44.5 27.499 44.5H32.499C32.6648 44.5 32.8238 44.4342 32.941 44.3169C33.0582 44.1997 33.124 44.0408 33.124 43.875C33.124 43.7092 33.0582 43.5503 32.941 43.4331C32.8238 43.3158 32.6648 43.25 32.499 43.25Z" fill="white"/>
            <path d="M43.125 43.25H38.125C37.9592 43.25 37.8003 43.3158 37.6831 43.4331C37.5658 43.5503 37.5 43.7092 37.5 43.875C37.5 44.0408 37.5658 44.1997 37.6831 44.3169C37.8003 44.4342 37.9592 44.5 38.125 44.5H43.125C43.2908 44.5 43.4497 44.4342 43.5669 44.3169C43.6842 44.1997 43.75 44.0408 43.75 43.875C43.75 43.7092 43.6842 43.5503 43.5669 43.4331C43.4497 43.3158 43.2908 43.25 43.125 43.25Z" fill="white"/>
        </svg>
    );
};

export default WebDesignActiveIcon;
